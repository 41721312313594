<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-row
          @click.prevent="collapse = !collapse"
          class="pr-0 pr-md-5"
          align-v="center"
        >
          <b-col
            cols="12"
            md="6"
          >
            <strong>{{ name }}</strong>
            <span
              v-if="accounts && accounts.transactions"
              class="pl-2"
            >
              <b-icon-caret-up-fill v-if="collapse"></b-icon-caret-up-fill>
              <b-icon-caret-down-fill v-else></b-icon-caret-down-fill>
            </span>
          </b-col>

          <b-col
            class="text-right"
            offset="0"
            :offset-md="!isComparing ? 3 : 0"
            cols="12"
            md="3"
            :class="isComparing ? 'pr-md-0' : ''"
          >
            <b-row
              no-gutters
              align-v="center"
            >
              <b-col class="pl-1">
                <strong>{{ accounts | total | currency }}</strong>
              </b-col>
              <b-col class="small text-center">
                <PercentDisplay
                  v-if="hasIncome"
                  type="income"
                  :new-amount="Math.abs(accounts.total)"
                  :old-amount="income"
                  class="pl-3"
                ></PercentDisplay>
              </b-col>
            </b-row>
          </b-col>

          <b-col
            class="text-right"
            cols="12"
            md="3"
            v-if="isComparing"
          >
            <b-row
              no-gutters
              align-v="center"
            >
              <b-col class="pl-3">
                <strong v-if="filters.display == 'money'">
                  {{ compared ? formatCurrency(compareSum) : formatCurrency(0) }}
                </strong>
                <strong v-else>{{ compared ? Math.abs(compareSum) : 0 | compFilt((totalComp !== 0) ? totalComp : 1) }}</strong>
              </b-col>
              <b-col class="small" v-if="compared">
                <span v-if="filters.display == 'money'" :class="(true) ? getPercentClass() : ''">
                  {{ compared ? formatCurrency(accounts.total - compareSum) : formatCurrency(0) }}
                </span>
                <PercentDisplay
                  v-else
                  type="difference"
                  :new-amount="income ? Math.round((accounts.total / income) * 100, 2) : 100"
                  :old-amount="calcCompPerc(((totalComp !== 0) ? totalComp : 1))"
                  class="pl-3"
                  :switchComp="switchComp"
                ></PercentDisplay>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <div
          class="d-inline"
          style="position: absolute; right: 1.5em; transform: translateY(-1.5em);"
        >
          <div
            class="text--sbs-red"
            @click.prevent="openModal = true"
          >
            <b-icon icon="graph-up"></b-icon>
          </div>
        </div>
      </b-card-header>

      <b-collapse
        v-model="collapse"
        v-if="accounts"
      >
        <b-card-body v-for="(t, idx) in accounts.transactions"
        :key="idx" class="p-0">
          <AccountDetail
            v-if="collapse"
            :transactions="t"
            :compared="compareTo ? compareTo[idx] : null"
            :isComparing="isComparing"
            :income="income"
            :totalComp="totalComp"
            :switchComp="switchComp"
            :accounttype="name"
            :filters="filters"
          ></AccountDetail>
        </b-card-body>
        <b-card-footer>
          <b-row class="pr-0 pr-md-5">
            <b-col
              class="text-md-right"
              cols="12"
              md="6"
            >
              <strong>Total {{ name }}:</strong>
            </b-col>
            <b-col
              class="text-right"
              offset="0"
              :offset-md="!isComparing ? 3 : 0"
              cols="12"
              md="3"
              :class="isComparing ? 'pr-md-0' : ''"
            >
              <b-row no-gutters>
                <b-col class="pl-1"><strong>{{ accounts | total | currency }}</strong></b-col>
                <b-col class="small text-center">
                  <PercentDisplay
                    v-if="hasIncome"
                    type="income"
                    :new-amount="accounts.total"
                    :old-amount="income"
                    class="pl-3"
                  ></PercentDisplay>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              class="text-right"
              cols="12"
              md="3"
              v-if="isComparing"
            >
              <b-row no-gutters>
                <b-col class="pl-3">
                  <strong v-if="filters.display == 'money'">
                    {{ compared ? formatCurrency(compareSum) : formatCurrency(0) }}
                  </strong>
                  <strong v-else>{{ compared ? Math.abs(compareSum) : 0 | compFilt((totalComp !== 0) ? totalComp : 1) }}</strong>
                </b-col>
                <b-col class="small" v-if="compared">
                  <span v-if="filters.display == 'money'" :class="(true) ? getPercentClass() : ''">
                    {{ compared ? formatCurrency(accounts.total - compareSum) : formatCurrency(0) }}
                  </span>
                  <PercentDisplay
                    v-else
                    type="difference"
                    :new-amount="income ? Math.round((accounts.total / income) * 100, 2) : 100"
                    :old-amount="calcCompPerc(((totalComp !== 0) ? totalComp : 1))"
                    class="pl-3"
                    :switch="switchComp"
                  ></PercentDisplay>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card-footer>
      </b-collapse>

      <b-modal
        v-model="openModal"
        hide-footer
        no-close-on-backdrop
        no-close-on-esc
        :title="name"
      >
        <LineChart
          :data="chartdata"
          :options="options"
        ></LineChart>
      </b-modal>
    </b-card>
  </div>
</template>
<script>
import AccountDetail from '@/components/profitloss/AccountDetail'
// import TableTest from '@/components/profitloss/TableTest'
import LineChart from '@/components/dashboard/reports/charts/Line'
import PercentDisplay from '@/components/profitloss/PercentDisplay'

export default {
  name: 'AccountSummary',
  components: { AccountDetail, LineChart, PercentDisplay },
  data () {
    return {
      collapse: false,
      openModal: false,
      compareTo: [],
      compareSum: 0,
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: false,
              callback: function (label, index, labels) {
                return '$' + label / 1000 + 'k'
              }
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: true
        },
        // responsive: true,
        // maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              // const label = data.labels[tooltipItem.index]
              const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              return this.$convertToCurrency(value)
            }
          }
        },
        plugins: {
          datalabels: {
            backgroundColor: 'transparent',
            borderRadius: 4,
            color: 'black',
            font: {
              weight: 'bold'
            },
            formatter: (val) => {
              if (val === 0) {
                return ''
              }
              return this.$convertToCurrency(val)
            },
            padding: 6,
            display: 'auto',
            clamp: false,
            anchor: 'start',
            align: 'top'
          }
        }
      }
    }
  },

  props: {
    name: String,
    accounts: Object,
    income: {
      type: Number,
      default: 0
    },
    compared: {
      type: Object,
      default: null
    },
    expand: {
      type: Boolean,
      default: false
    },
    isComparing: {
      type: Boolean,
      default: false
    },
    totalComp: {
      type: Number,
      default: 0
    },
    switchComp: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object
    }
  },

  mounted () {
    if (this.expand) {
      this.collapse = true
    }
    if (this.compared) {
      this.calcSumTotal()
    }
    if (this.accounts && this.accounts.transactions && this.accounts.transactions !== null) {
      this.sortCompare()
    }
  },

  methods: {
    calcCompPerc (comp) {
      let val = 0
      this.compared.transactions.forEach(t => {
        val += t.balance
      })
      return Math.abs((val / comp) * 100)
    },

    formatCurrency (amount) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount)
    },

    getPercentClass () {
      if (this.name === 'Income' || this.name === 'Other Income') {
        return (this.accounts.total >= this.compareSum) ? 'text-success' : 'text-danger'
      } else if (this.name === 'Cost of Goods Sold' || this.name === 'Expenses' || this.name === 'Other Expenses') {
        return (this.accounts.total >= this.compareSum) ? 'text-danger' : 'text-success'
      }
      return (this.accounts.total >= this.compareSum) ? 'text-success' : 'text-danger'
    },

    calcSumTotal () {
      let val = 0
      this.compared.transactions.forEach(t => {
        val += t.balance
      })
      this.compareSum = val
    },

    // This links the compare items to the account items for easier sorting
    // Currently links on the top level accountnames that exsist
    sortCompare () {
      this.accounts.transactions.forEach(t => {
        let matchFound = false
        if (this.compared) {
          this.compared.transactions.forEach(c => {
            if (t.accountname === c.accountname) {
              this.compareTo.push(c)
              matchFound = true
            }
          })
        }
        if (!matchFound) {
          // If no match was found, push an empty value
          this.compareTo.push(null)
        }
      })
    }
  },

  computed: {
    hasIncome () {
      return (this.income && +this.income !== 0)
    },

    chartdata: function () {
      const labels = []
      const data = []

      this.accounts.chartdata.labels.forEach(e => {
        const lab = '0' + e.toString()
        labels.push(lab.slice(-2))
      })

      this.accounts.chartdata.data.forEach(e => {
        data.push(Math.abs(parseFloat(e).toFixed(2)))
      })

      const chartData = {
        labels: labels,
        datasets: [
          {
            label: this.accounts.chartdata.label,
            data: data,
            borderWidth: 1,
            backgroundColor: '#BE191E33',
            borderColor: '#A0171B',
            pointBorderColor: '#A0171B'
          }
        ]
      }

      if (this.compared && this.compared.chartdata && this.compared.chartdata.data) {
        const data2 = []
        this.compared.chartdata.data.forEach(e => {
          data2.push(Math.abs(parseFloat(e).toFixed(2)))
        })
        chartData.datasets.push(
          {
            label: this.compared.chartdata.label,
            data: data2,
            borderWidth: 1,
            backgroundColor: '#FF6C0033',
            borderColor: '#D35900',
            pointBorderColor: '#D35900'
          }
        )
      }

      return chartData
    }
  },

  filters: {
    total: function (val) {
      let t = 0
      if (val) {
        t = val.total
      }
      return Math.abs(t)
    },

    compFilt: function (val, comp) {
      return (Math.round(((val / comp) * 100) * 10) / 10) + '%'
    },

    isNegative (val) {
      if (val.toString()[0] === '-') {
        return '(' + val.toString().substring(1) + ')'
      }
      return val
    }
  }
}
</script>
