<template>
  <b-container fluid class="text-left">

    <div class="filter-bar p-2 mb-3">
      <b-navbar toggleable="xl">
        <b-navbar-toggle target="filter-collapse"></b-navbar-toggle>
        <b-collapse
          id="filter-collapse"
          is-nav
        >
          <div>
            <div class="d-flex flex-wrap align-items-center">
              <button
                @click="resetFilters()"
                class="filter-btn reset mr-2 mt-4"
              >Reset</button>
              <b-form-group label="Select Company" class="mb-0 mr-2">
                <b-form-select
                  :options="companies"
                  text-field="name"
                  value-field="id"
                  v-model="filters.company_id"
                  plain
                  class="select company"
                  style="margin-left: 0 !important;"
                >
                  <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Select Company</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>

              <div class="filter-btn-group mt-4 mr-2" style="margin-left: 0 !important;">
                <button
                  class="filter-btn left"
                  :class="filters.type == 'all' ? 'selected' : ''"
                  @click="filters.type = 'all'"
                >
                  All
                </button>
                <button
                  class="filter-btn right"
                  :class="filters.type == 'direct' ? 'selected' : ''"
                  @click="filters.type = 'direct'"
                >
                  Direct
                </button>
              </div>
              <b-form-group label="Select Year" class="mb-0 mr-2">
                <b-form-select
                  :options="years"
                  v-model="filters.year"
                  plain
                  required
                  class="select"
                  style="margin-left: 0 !important;"
                >
                  <template #first>
                    <b-form-select-option :value="null">Start Year</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>

              <b-form-group label="Select Period" class="mb-0 mr-2">
                <b-form-select
                  :options="(isComparingIndustry) ? quarters : periods"
                  v-model="filters.startperiod"
                  plain
                  class="select"
                  style="margin-left: 0 !important;"
                >
                  <template #first>
                    <b-form-select-option :value="null">Start Period</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>

              <b-form-group label="End Period" class="mb-0 mr-2">
                <b-form-select
                  :options="(isComparingIndustry) ? quarters : periods"
                  v-model="filters.endperiod"
                  plain
                  class="select"
                  style="margin-left: 0 !important;"
                >
                  <template #first>
                    <b-form-select-option :value="null">End Period</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>

              <div class="filter-btn-group mt-4 mr-2">
                <button
                  class="filter-btn left"
                  :class="filters.comparewho == 'Self' ? 'selected' : ''"
                  @click="filters.comparewho = 'Self'"
                >
                  Compare to: Self
                </button>
                <button
                  class="filter-btn right"
                  :class="filters.comparewho == 'Industry' ? 'selected' : ''"
                  @click="setCompareToIndustry()"
                >
                  Compare to: Industry
                </button>
              </div>

              <b-form-group label="Compare Year" class="mb-0 mr-2">
                <b-form-select
                  :options="years"
                  v-model="filters.compareyear"
                  plain
                  required
                  class="select"
                  style="margin-left: 0 !important;"
                >
                  <template #first>
                    <b-form-select-option :value="null">Start Compare Year</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>

              <b-form-group label="Compare Start" class="mb-0 mr-2">
                <b-form-select
                  :options="(isComparingIndustry) ? quarters : periods"
                  v-model="filters.comparestartperiod"
                  plain
                  class="select"
                  style="margin-left: 0 !important;"
                >
                  <template #first>
                    <b-form-select-option :value="null">Start Compare Period</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>

              <b-form-group label="Compare End" class="mb-0 mr-2">
                <b-form-select
                  :options="(isComparingIndustry) ? quarters : periods"
                  v-model="filters.compareendperiod"
                  plain
                  class="select"
                  style="margin-left: 0 !important;"
                >
                  <template #first>
                    <b-form-select-option :value="null">End Compare Period</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>

              <b-form-group label="Revenue Bucket" class="mb-0 mr-2" v-if="filters.comparewho == 'Industry'">
                <b-form-select
                  :options="compareBuckets"
                  v-model="filters.comparebucket"
                  plain
                  class="select"
                  v-if="filters.comparewho == 'Industry'"
                  style="margin-left: 0 !important;"
                >
                  <template #first>
                    <b-form-select-option :value="null">Revenue Bucket</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>

              <div class="filter-btn-group mt-4 mr-2" v-if="filters.comparewho == 'Self'">
                <button
                  class="filter-btn left"
                  :class="filters.display == 'percent' ? 'selected' : ''"
                  @click="filters.display = 'percent'"
                >
                  %
                </button>
                <button
                  class="filter-btn right"
                  :class="filters.display == 'money' ? 'selected' : ''"
                  @click="filters.display = 'money'"
                >
                  $
                </button>
              </div>

              <b-button
                @click.prevent="load"
                :disabled="!filtersAreValid"
                variant="primary"
                size="sm"
                class="filter-btn mt-4"
              >Load</b-button>
            </div>
          </div>
        </b-collapse>
      </b-navbar>
    </div>
    <div v-if="report && report.length > 0" style="color: white; font-size:medium; text-align: center;"><small>Last Synced: {{ report[0].synced }}</small></div>
    <b-container>
      <b-overlay
        :show="loading"
        v-if="!filtersChanged"
      >
        <b-card
          v-if="report && report.length > 0"
          class="text--black"
        >
          <b-card-title>
            <b-row>
              <b-col class="text-center">
                {{ this.companyname }}
              </b-col>
            </b-row>

            <b-row class="small mt-4" no-gutters>
              <b-col
                cols="12"
                md="6"
              >Profit &amp; Loss</b-col>
              <b-col
                offset="0"
                :offset-md="(report && report.length > 1) ? 0 : 3"
                cols="12"
                md="3"
                class="text-left small"
                :class="(report && report.length > 1) ? 'text-md-left' : ''"
              >
                <div v-if="filters.comparewho === 'Industry'">
                  <strong>{{ filters.startDate }} to <span v-if="filters.endDate !== null">{{ filters.endDate }}</span><span v-else>Current</span></strong>
                </div>
                <div v-else>
                  <strong>{{ filters.startDate }} to <span v-if="filters.endDate !== null">{{ filters.endDate }}</span><span v-else>Current</span></strong>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="3"
                class="text-left text-md-left small"
                v-if="(report && report.length > 1)"
              >
                <b>
                  <span style="color: #BE191E" v-if="filters.comparewho === 'Industry'">Industry Average {{ filters.comparestartperiod }} - {{ filters.compareendperiod }}</span>
                  <span v-else style="color: #BE191E">{{ filters.comparestart }} to <span v-if="filters.compareend !== null" style="color: #BE191E">{{ filters.compareend }}</span><span v-else style="color: #BE191E">Current</span></span>
                </b>
              </b-col>
            </b-row>
          </b-card-title>

          <b-card>
            <b-card-title>Ordinary Income/Expense
              <span style="float: right; font-size: medium; font-family: sans-serif;">
                <span style="padding-right: 25px">Actuals</span>
                <span style="padding-right: 55px;">% of Income</span>
                <span v-if="report && report.length > 1" style="padding-right: 25px; color: #BE191E;">Compare</span>
                <span v-if="report && report.length > 1" style="padding-right: 75px; color: #BE191E">Difference</span>
              </span>
            </b-card-title>
            <AccountSummary
              name="Income"
              :accounts="report[0].accounts.Income"
              :compared="(report && report.length > 1) ? report[1].accounts.Income : null"
              :isComparing="isComparing"
              :totalComp="(report && report.length > 1 && report[1].accounts.length !== 0) ? report[1].accounts.Income.total : 0"
              :filters="filters"
              hidePercent
            ></AccountSummary>
            <AccountSummary
              name="Cost of Goods Sold"
              :accounts="report[0].accounts.CostOfGoodsSold"
              :compared="report[1] != null ? report[1].accounts.CostOfGoodsSold : null"
              :isComparing="isComparing"
              :income="report[0].totalincome"
              :totalComp="(report && report.length > 1 && report[1].accounts.length !== 0) ? report[1].accounts.Income.total : 0"
              :filters="filters"
            ></AccountSummary>

            <TotalDisplay
              label="Gross Profit"
              :total="report[0].gross"
              :compared="report[1] != null ? report[1].gross : null"
              :isComparing="isComparing"
              :income="report[0].totalincome"
              :totalComp="(report && report.length > 1 && report[1].accounts.length !== 0) ? report[1].accounts.Income.total : 0"
              :filters="filters"
              switchComp
            ></TotalDisplay>

            <AccountSummary
              name="Expenses"
              :accounts="report[0].accounts.Expense"
              :compared="report[1] != null ? report[1].accounts.Expense : null"
              :isComparing="isComparing"
              :income="report[0].totalincome"
              :totalComp="(report && report.length > 1 && report[1].accounts.length !== 0) ? report[1].accounts.Income.total : 0"
              :filters="filters"
            ></AccountSummary>

            <TotalDisplay
              label="Net Ordinary Income"
              :total="report[0].net"
              :compared="report[1] != null ? report[1].net : null"
              :isComparing="isComparing"
              :income="report[0].totalincome"
              :totalComp="(report && report.length > 1 && report[1].accounts.length !== 0) ? report[1].accounts.Income.total : 0"
              :filters="filters"
              switchComp
            ></TotalDisplay>
          </b-card>

          <b-card class="mt-3">
            <b-card-title>Other Income/Expense</b-card-title>
            <AccountSummary
              name="Other Income"
              :accounts="report[0].accounts.OtherIncome"
              :compared="report[1] != null ? report[1].accounts.OtherIncome : null"
              :isComparing="isComparing"
              :income="report[0].totalincome"
              :totalComp="(report && report.length > 1 && report[1].accounts.length !== 0) ? report[1].accounts.Income.total : 0"
              :filters="filters"
            ></AccountSummary>
            <AccountSummary
              name="Other Expenses"
              :accounts="report[0].accounts.OtherExpense"
              :compared="report[1] != null ? report[1].accounts.OtherExpense : null"
              :isComparing="isComparing"
              :income="report[0].totalincome"
              :totalComp="(report && report.length > 1 && report[1].accounts.length !== 0) ? report[1].accounts.Income.total : 0"
              :filters="filters"
            ></AccountSummary>

            <TotalDisplay
              label="Net Other Income"
              :total="report[0].netother"
              :compared="report[1] != null ? report[1].netother : null"
              :isComparing="isComparing"
              :income="report[0].totalincome"
              :totalComp="(report && report.length > 1 && report[1].accounts.length !== 0) ? report[1].accounts.Income.total : 0"
              :filters="filters"
            ></TotalDisplay>
          </b-card>

          <TotalDisplay
            label="Net Income"
            :total="report[0].total"
            :compared="report[1] != null ? report[1].total : null"
            class="card-body mt-3"
            :isComparing="isComparing"
            :income="report[0].totalincome"
            :totalComp="(report && report.length > 1 && report[1].accounts.length !== 0) ? report[1].accounts.Income.total : 0"
            :filters="filters"
            switchComp
          ></TotalDisplay>
        </b-card>
      </b-overlay>
    </b-container>
  </b-container>
</template>
<script>
import StorageService from '@/services/StorageService'
import UserService from '@/services/UserService'
import CompanyService from '@/services/CompanyService'
import DashboardReportService from '@/services/DashboardReportService'
import AccountSummary from '@/components/profitloss/AccountSummary'
import TotalDisplay from '@/components/profitloss/TotalDisplay'
import moment from 'moment'
import AdminService from '@/services/AdminService'

export default {
  name: 'Summary',
  components: { AccountSummary, TotalDisplay },
  data () {
    return {
      loading: false,
      filtersDefault: {
        company_id: null,
        type: 'all',
        startDate: 0,
        endDate: 0,
        year: null,
        period: null,
        comparewho: 'Self',
        compareyear: null,
        compareperiod: null,
        comparebucket: null,
        comparestart: 0,
        compareend: 0,
        display: 'percent',
        compareendperiod: null,
        comparestartperiod: null,
        startperiod: null,
        endperiod: null
      },
      filters: {},
      filtersChanged: true,
      companies: [],
      company: null,
      years: [],
      buckets: [],
      periods: ['Q1', 'Q2', 'Q3', 'Q4', { label: 'Months', options: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] }],
      quarters: ['Q1', 'Q2', 'Q3', 'Q4'],
      report: null
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    async init () {
      if (UserService.isAdmin()) {
        this.companies = await AdminService.getCompanies()
      } else {
        this.companies = await UserService.getCompanies()
      }
      this.getFilters()
    },

    load () {
      this.report = null
      if (this.filtersAreValid) {
        this.loading = true
        StorageService.set('summaryFilters', this.processFilters(), null)
        DashboardReportService.get('ProfitLoss', this.filters, true).then((response) => {
          this.report = response.data
          this.filtersChanged = false
        }).catch((err) => {
          this.$aimNotify.error(err.response)
        }).finally(() => {
          this.loading = false
        })
      }
    },
    setCompareToIndustry () {
      this.filters.comparewho = 'Industry'
      this.filters.display = 'percent'
    },

    processFilters () {
      if (this.filters.year !== null) {
        if (this.filters.startperiod !== null && this.filters.endperiod !== null) {
          if (this.filters.startperiod[0] === 'Q') {
            // given a quarter
            this.filters.startDate = moment().year(this.filters.year).quarter(this.filters.startperiod[1]).startOf('quarter').format('YYYY-MM-DD')
          } else {
            this.filters.startDate = moment().year(this.filters.year).month(this.filters.startperiod).startOf('month').format('YYYY-MM-DD')
          }
          if (this.filters.endperiod[0] === 'Q') {
            // given a quarter
            this.filters.endDate = moment().year(this.filters.year).quarter(this.filters.endperiod[1]).endOf('quarter').format('YYYY-MM-DD')
          } else {
            this.filters.endDate = moment().year(this.filters.year).month(this.filters.endperiod).endOf('month').format('YYYY-MM-DD')
          }
        } else {
          // we only want the year
          this.filters.startDate = moment().year(this.filters.year).startOf('year').format('YYYY-MM-DD')
          this.filters.endDate = moment().year(this.filters.year).endOf('year').format('YYYY-MM-DD')
        }

        if (this.filters.compareyear !== null) {
          if (this.filters.comparestartperiod !== null) {
            if (this.filters.comparestartperiod[0] === 'Q') {
              this.filters.comparestart = moment().year(this.filters.compareyear).quarter(this.filters.comparestartperiod[1]).startOf('quarter').format('YYYY-MM-DD')
            } else {
              this.filters.comparestart = moment().year(this.filters.compareyear).month(this.filters.comparestartperiod).startOf('month').format('YYYY-MM-DD')
            }
            if (this.filters.compareendperiod[0] === 'Q') {
              this.filters.compareend = moment().year(this.filters.compareyear).quarter(this.filters.compareendperiod[1]).endOf('quarter').format('YYYY-MM-DD')
            } else {
              this.filters.compareend = moment().year(this.filters.compareyear).month(this.filters.compareendperiod).endOf('month').format('YYYY-MM-DD')
            }
          }
        } else {
          this.filters.comparestart = null
          this.filters.compareend = null
        }
      } else {
        this.filters.startDate = null
        this.filters.endDate = null
      }
      return this.filters
    },

    getFilters () {
      let filters = StorageService.get('summaryFilters')
      if (!filters) {
        filters = JSON.parse(JSON.stringify(this.filtersDefault))
      }
      if (!filters.display) {
        filters.display = 'percent'
      }
      StorageService.set('summaryFilters', filters, null)

      if (!filters.company_id) {
        if (this.companies.length > 0) {
          filters.company_id = this.companies[0].id
        }
      }
      this.filters = filters
    },

    resetFilters () {
      const filters = JSON.parse(JSON.stringify(this.filtersDefault))
      console.log('filters', filters)
      console.log('filters default', JSON.parse(JSON.stringify(this.filtersDefault)))

      StorageService.set('summaryFilters', filters, null)

      if (!filters.company_id) {
        if (this.companies.length > 0) {
          filters.company_id = this.companies[0].id
        }
      }

      this.filters = filters
      console.log('filters this', this.filters)
      this.report = null
    },

    getCompanyInfo () {
      // get additional company information needed for this interface
      if (+this.filters.company_id > 0 && (!this.company || +this.company.id !== +this.filters.company_id)) {
        CompanyService.getCompany(+this.filters.company_id).then((response) => {
          this.company = response.data.info.company
          this.years = response.data.info.years

          if (!this.years.some(y => y === this.filters.year)) {
            this.filters.year = null
            this.filters.compareyear = null
          }

          this.buckets = response.data.info.buckets
          this.filters.comparebucket = this.company.industryrevenuebucket_id
        }).catch((err) => {
          this.$aimNotify.error(err.response)
        })
      }
    }
  },

  computed: {
    filtersAreValid: function () {
      return (this.filters && +this.filters.company_id > 0 && this.filters.year !== null)
    },

    companyname: function () {
      let name = null
      if (+this.filters.company_id > 0 && this.companies.length > 0) {
        this.companies.some((c) => {
          if (+c.id === +this.filters.company_id) {
            name = c.name
            return true
          } else {
            return false
          }
        })
      }
      return name
    },

    isComparing: function () {
      if (this.report && this.report.length > 1) {
        return true
      } else {
        return false
      }
    },

    isComparingIndustry () {
      return (this.filters && this.filters.comparewho === 'Industry')
    },

    compareBuckets () {
      const buckets = []
      this.buckets.forEach(b => {
        const minTxt = this.$convertToCurrency(b.min, false)
        const maxTxt = this.$convertToCurrency(b.max, false)
        buckets.push({ value: +b.id, text: (b.max ? minTxt + ' - ' + maxTxt : minTxt + '+') })
      })

      return buckets
    }
  },

  watch: {
    'filters.company_id': function (n, o) {
      if (n && +n > 0) {
        this.getCompanyInfo()
      } else {
        this.company = null
      }
    },

    filters: {
      handler () {
        this.filtersChanged = true
        this.load()
      },
      deep: true
    }
  }
}
</script>
